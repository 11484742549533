'use client'; // Error components must be Client Components

import { useEffect } from 'react';
import { Button } from '@/components/Base/Button';

export default function ErrorBoundary({ error, reset }: Readonly<{ error: Error; reset: () => void }>) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <div>
      <h2>Something went wrong!</h2>
      <Button
        data-testid="button:reset"
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }>
        Try again
      </Button>
    </div>
  );
}
